import './styles/app.scss';
import './styles/css-fadeshow.min.css';

// start the Stimulus application
import * as bootstrap from 'bootstrap';

import 'jquery'

import AOS from 'aos';
import 'aos/dist/aos.css'; 

import './js/carousel';
import './js/jquery.waypoints.min';
import './js/jquery.animateNumber.min';
import './js/isotope.js'

const $ = require('jquery');

AOS.init(
  {
    disable: function() {
      var maxWidth = 997;
      return window.innerWidth < maxWidth;
    },
    startEvent: 'load',
  }
);

$(window).on('load', function() {
  AOS.refresh();
});
/*
const hamburger_menu = document.querySelector(".hamburger-menu");
const container = document.querySelector(".main-body");
const navbar = document.querySelector(".navbar");
const links = document.querySelector(".links");
    hamburger_menu.addEventListener("click", () => {
    container.classList.toggle("active");
    links.classList.toggle("active");
    navbar.classList.toggle("active");
});
*/
const hamburger_menu = document.querySelector(".hamburger-menu");
const navbar = document.querySelector(".device");
let burger = document.getElementById('burger'),
	 nav    = document.getElementById('main-nav');

burger.addEventListener('click', function(e){
	this.classList.toggle('is-open');
	nav.classList.toggle('is-open');
});

hamburger_menu.addEventListener("click", () => {
  navbar.classList.toggle("active");
});
